<template>
  <el-skeleton style="width: 100%;min-height: 100vh;" animated v-if="skeletonShow">
    <template slot="template">
      <div style="width: 1400px;margin: 20px auto 20px;">
        <el-skeleton-item style="width: 1400px; height: 439px;" />
      </div>
      <div style="width: 1400px;margin: 0 auto 20px;">
        <el-skeleton-item style="width: 1400px; height: 52px;" />
      </div>
      <div style="width: 1400px;margin: 0 auto 20px;">
        <div style="display: flex;align-items: center;justify-content: space-between;">
          <el-skeleton-item v-for="(item, index) in 4" :key="index" style="width: 335px;
height: 175px;" />
        </div>
      </div>
      <div style="width: 1400px;margin: 0 auto 20px;">
        <el-skeleton-item style="width: 1400px;height: 700px;" />
      </div>
    </template>
  </el-skeleton>

  <div v-else>
    <div class="bg">
      <div class="header_search">
        <div style="display: flex; align-items: center">
          <img src="@/assets/image/icon2.png" alt="" />
          <el-input v-model="keyword" placeholder="请输入关键字" style="width: 540px"></el-input>
        </div>
        <div style="display: flex; align-items: center">
          <div class="search_line"></div>
          <div class="search_text" @click="search()">搜索文档</div>
        </div>
      </div>
      <div class="tag_contain">
        <div class="tag_contain_left">
          <img src="@/assets/image/icon5.png" alt="" />
          热门标签
        </div>
        <div class="tag_contain_list">
          <div class="tag_contain_item" v-for="(item, index) in tag" :key="index"
            @click="keyword = item.name; search()">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="contain_login">
        <div class="swiper" :class="{ swiper_active: token }">
          <el-carousel height="439px">
            <el-carousel-item v-for="item in swiperList" :key="item">
              <img :src="`${$imgURL}${item.path}`" alt="" class="swiper_item" @click="jumpbannerRichText(item)" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div v-if="!token">
          <div v-if="bindPhone">
            <bindPhonePage @backLoginInfo="backLoginInfo" :wxCode="wxCode" />
          </div>
          <div v-else>
            <login @backLoginInfo="backLoginInfo" :inviteCode="invite_code" @backalipayLogin="backalipayLogin"></login>
          </div>

        </div>
      </div>
    </div>
    <div style="width: 100%; height: 22px"></div>
    <div class="notice_contain">
      <div class="notice_contain_left">
        <div>
          <img src="@/assets/image/icon15.png" alt="" />
          网站公告
        </div>
        <div @click="goNoticeDetail()">{{ pushedData.title || "" }}</div>
      </div>
      <div class="notice_contain_right">
        <div>{{ pushedData.created_at.split("T")[0] }}</div>
        <div @click="goNoticeList">
          更多
          <img src="@/assets/image/icon16.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 后台 文档管理-分类管理加数据(接口CategoryAPI-ListCategory)-->
    <div class="classify_contain">
      <div class="classify_contain_item" v-for="(item, index) in categoryList" :key="index">
        <div class="one" @click="jumpDocClassify(item)">
          <img :src="$imgURL + item.icon" alt="" class="oneicon" />
          <div>{{ item.title || "" }}</div>
        </div>
        <div class="line"></div>
        <div class="two">
          <div class="two_item" v-for="(i, sub) in item.children" :key="sub" @click="jumpDocClassify(i, item.title)">
            {{ i.title || "" }}
          </div>
        </div>
      </div>
    </div>
    <!-- 热门文章 没接口 -->
    <div class="hot_contain">
      <div class="hot_contain_tip">
        <div>
          <!-- 热门文章 -->
          热门文章
          <p></p>
        </div>
        <div @click="lookAll" style="cursor: pointer">
          查看全部<img src="@/assets/image/icon21.png" alt="" />
        </div>
      </div>
      <div class="hot_list">
        <div class="hot_list_item" v-for="(item, index) in hotList" :key="index" @click="goDocDetail(item)">
          <img ref="image" :src="item.attachment.hash
    ? `${$imgURL + '/view/cover/' + item.attachment.hash}`
    : icon41
    " alt="" class="left_img" />
          <div class="hot_info">
            <div class="hot_info_title">
              {{ item.title || "" }}
            </div>
            <!-- <div class="hot_info_desc">
              {{ item.description }}
            </div>
            <div class="hot_info_time">
              <div>
                {{ item.created_at }}
              </div>
              <img src="@/assets/image/icon22.png" alt="" />
            </div>
            <div class="hot_info_line"></div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 广告位 -->
    <div class="ad" @click="link_web">
      <img :src="$imgURL + adv.image" alt="" />
    </div>
    <!-- 合作伙伴 -->
    <div class="partner_contain">
      <div class="partner_contain_tip">
        <div>
          合作伙伴
          <p></p>
        </div>
        <!-- <div>查看全部<img src="@/assets/image/icon21.png" alt="" /></div> -->
      </div>
      <div class="partner_list">
        <div class="partner_list_item" v-for="(item, index) in partner" :key="index" @click="goPartner(item.url)">
          <img :src="$imgURL + item.logo" alt="" />
        </div>
      </div>
    </div>
    <div style="height: 50px"></div>
    <div :class="screenWidth > 1600 ? 'kf' : 'kefu2'" @click="show = !show">
      <img src="@/assets/image/icon28.png" alt="" />
      客服
    </div>
    <el-dialog title=" " :visible.sync="show" width="15%" custom-class="customClassDialog" :show-close="false">
      <div style="display: flex; align-items: center; justify-content: center">
        <telToast :show="show" v-if="show" :type="1" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import login from "../components/login.vue";
import bindPhonePage from "../components/bindPhonePage.vue";
import { get, postJSON } from "/utils/request";
import telToast from "@/components/telToast.vue";
import icon41 from "@/assets/image/icon41.png";

export default {
  components: {
    login,
    telToast,
    bindPhonePage
  },
  data() {
    return {
      token: window.localStorage.getItem("token"),
      keyword: "",
      tag: [],
      swiperList: [],
      hotList: [], //热门文章
      partner: [1, 1, 1, 1, 1, 1], //合作伙伴
      noticeList: [], //公告列表
      pushedData: null, //存储推送的数据
      categoryList: [], //分类列表
      adv: {},
      show: false,
      icon41: icon41,
      screenWidth: 0,
      bindPhone: false,
      wxCode: '',
      auth_code: '',
      skeletonShow: true
    };
  },
  async created() {
    this.auth_code = this.getalipayCodeStatus('auth_code')
    if (this.auth_code !== '无') {
      this.handleAuthCode(this.auth_code);
    }
    if (this.$route.query.invite_code) {
      this.invite_code = this.$route.query.invite_code;
    }
    const requests = [
      this.getHotDocList(),
      this.getConfig(),
      this.getNoticeList(),
      this.getCategoryList(),
    ];
    await Promise.all(requests);
    setTimeout(() => {
      this.skeletonShow = false
    }, 300)
  },
  mounted() {
    this.updateScreenWidth(); // 初始化宽度
    this.resetImageHeight()
    window.addEventListener("resize", this.updateScreenWidth); // 监听窗口大小变化
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScreenWidth); // 移除监听器
  },
  watch: {
    $route() {
      if (this.$route.query.code != undefined) {
        // this.bindPhone = true
        this.wxCode = this.$route.query.code
        window.localStorage.setItem('wxCode', this.$route.query.code)
        // this.getWeixin(this.$route.query.code, this.$route.query.state);
        this.getCodeStatus(this.$route.query.code)
      }
      // if (this.$route.query.auth_code != undefined) {
      //   console.log('auth_code', this.$route.query.auth_code);
      //   window.localStorage.setItem('auth_code', this.$route.query.auth_code)
      //   this.getalipayCodeStatus(this.$route.query.auth_code)
      // }
    }
  },
  methods: {
    getCodeStatus(code) {
      postJSON("/wxchat/callback", { code }).then((res) => {
        console.log('扫码绑定返回值22', res);
        if (res.message !== 'success') {
          this.$message.error(res.message);
          return;
        }
        if (res.data.status == 2) {
          window.localStorage.setItem("wxopenid", res.data.openid);
          this.bindPhone = true
          return
        }
        this.token = res.data.token;
        window.localStorage.setItem("token", res.data.token);
        window.localStorage.setItem("userInfo", JSON.stringify(res.data.user));
        this.$router.go(0);
      });

    },
    getalipayCodeStatus(param) {
      // let url = decodeURI(location.href)
      // let theRequest = new Object()
      // if (url.indexOf('?') !== -1) {
      //   let str = url.substr(1);
      //   let strs = str.split('&');
      //   for (let i = 0; i < strs.length; i++) {
      //     theRequest[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1]);
      //   }
      // }
      // if (theRequest.auth_code) {
      //   let res = await postJSON("/alipay/register", {
      //     code: '8888',
      //     authCode:theRequest.auth_code,
      //     mobile: '15513738565'
      //   })
      //   console.log('支付宝res', res);
      // }
      let urlParams = new URLSearchParams(window.location.search);
      console.log('--------urlParams---------', urlParams);
      return urlParams.get(param) || '无';
    },
    async handleAuthCode(auth_code) {
      window.localStorage.setItem("auth_code", auth_code);

      let res = await postJSON("/alipay/login", {
        // code: '8888',
        authCode: auth_code,
        // mobile: '15513738565'
      })
      console.log('支付宝res', res);
      if (res.message !== 'success') {
        // this.$message.error(res.message);
        // 不成功 清除数据重新登录
        const url = new URL(window.location);
        url.searchParams.delete('auth_code');
        url.searchParams.delete('app_id');
        url.searchParams.delete('source');
        url.searchParams.delete('scope');
        url.searchParams.delete('state');
        window.history.replaceState({}, '', url.toString());
        // 清除缓存 (例如：localStorage 或 sessionStorage)
        window.localStorage.removeItem('auth_code'); // 根据实际情况清除缓存
        // this.$router.go(0)
        return;
      }
      if (res.data.status == 2) {
        window.localStorage.setItem("aliopenid", res.data.openid);
        this.bindPhone = true
        return
      }
      this.token = res.data.token;
      window.localStorage.setItem("token", res.data.token);
      window.localStorage.setItem("userInfo", JSON.stringify(res.data.user));
      this.$router.go(0);
    },
    resetImageHeight() {
      // 210/297
      // 重置封面高度：1. 读取封面宽度，2，根据宽高比计算高度
      console.log('image----------!!!!!!!', this.$refs.image);

      const image = this.$refs.image.$el
      const width = image.offsetWidth
      const height = (width * 297) / 210
      image.style.height = `${height - 10}px`
    },
    getConfig() {
      // 后台配置
      get("/config/hotSearch", {}).then((res) => {
        console.log(res);
        this.tag = res.data ?? [];
      });
      // 轮播图 /banner/list
      get("/banner/list", {
        enable: true,
        field: ["id", "title", "path", "url"],
        type: 0, // 0，网站横幅
      }).then((res) => {
        console.log(res);
        this.swiperList = res.banner;
      });
      // 合作伙伴
      get("/config/friend", {}).then((res) => {
        console.log(res);
        this.partner = res.data ?? [];
      });
      postJSON("/adv", { adv_id: 1 }).then((res) => {
        console.log(res);
        this.adv = res.data ?? {};
      });
    },
    getHotDocList() {
      get("/document/list", {
        page: 1,
        size: 6, // 每页显示数量
        // limit: 6,
        // is_recommend: true,
      }).then((res) => {
        console.log(res);
        if (!res.document) {
          this.hotList = [];
          return;
        }
        let arr = [];
        res.document.map((item) => {
          if (item.created_at) {
            let date = new Date(item.created_at);
            date.setHours(date.getHours());
            item.created_at = date.toLocaleString();
            console.log(date.toLocaleString());
          }
          arr.push(item);
        });
        this.hotList = arr ?? [];
      });
    },
    getNoticeList() {
      // 公告列表
      get("/article/list", {
        page: 1,
        size: 10,
        is_notice: 1,
      }).then((res) => {
        console.log(res);
        if (!res.article) {
          this.noticeList = [];
          return;
        }
        this.noticeList = res.article ?? [];
        if (res.article.length > 0) {
          setInterval(this.pushData, 2000);
        }
      });
    },
    getCategoryList() {
      get("/category/list", {}).then((res) => {
        console.log(res);
        let once_category = [];
        // let two_category = [];

        // res.category.map((item) => {
        //   if (!item.parent_id) {
        //     let categories_obj = {
        //       ...item,
        //       children: [],
        //     };
        //     once_category.push(categories_obj);
        //   } else {
        //     two_category.push(item);
        //   }
        // });
        // once_category.map((item) => {
        //   two_category.map((item2) => {
        //     if (item.id == item2.parent_id) {
        //       item.children.push(item2);
        //     }
        //   });
        // });
        // let arr = [];
        // once_category.map((item, index) => {
        //   if (index < 1) {
        //     arr.push(item);
        //   }
        // });
        // let arr2 = [];
        // arr.forEach((item) => {
        //   const newItem = {
        //     ...item,
        //     children:
        //       item.children.length > 6
        //         ? item.children.slice(0, 6)
        //         : item.children,
        //   };
        //   arr2.push(newItem);
        // });
        // this.categoryList = arr2;
        // console.log("一级分类", once_category);
        // console.log("二级分类", two_category);

        // /uploads/b/3/5/7/6/b3576170af4303818240a532b2ddabe3.png
        res.category.map((item) => {
          if (!item.parent_id) {
            once_category.push(item)
          }
          return item
          //       item.children.length > 6
          //         ? item.children.slice(0, 6)
          //         : item.children,
        });
        let arr2 = once_category.length > 6 ? once_category.slice(0, 6) : once_category

        let cate_arr = [{ id: '', title: '全部分类', icon: '/uploads/b/3/5/7/6/b3576170af4303818240a532b2ddabe3.png', children: arr2 }, { title: '文章定制', icon: '/uploads/1/a/a/3/1/1aa31acc12c15d63fec353b026aefe8d.png', children: [{ title: 'PPT定制' }, { title: '专业稿件' }, { title: '标书撰写' }, { title: '报告总结' }, { title: '计划/方案' }, { title: '教案撰写' }] }, { title: '硕博辅导', icon: '/uploads/4/9/7/1/2/497124eae96cc56344ec972242ecc10d.png', children: [{ title: '硕士辅导' }, { title: '博士辅导' }, { title: '1对1辅导' }] }, { title: '文章润色/格式排版/人工降重', icon: '/uploads/c/c/c/a/c/cccaccf91ff37c3e538cf497dc172942.png', children: [{ title: '专业排版' }, { title: '文章润色' }, { title: '人工降重' }] }]
        // this.categoryList = arr2.concat(cate_arr)
        this.categoryList = cate_arr
        console.log("this.categoryList", JSON.stringify(this.categoryList));


      });
    },
    pushData() {
      // 获取当前推送的数据
      this.pushedData = this.noticeList.shift();
      // 将数组第一个元素移至数组末尾，实现循环推送
      this.noticeList.push(this.noticeList[0]);
      // console.log("当前数据", this.pushedData);
    },
    search() {
      if (!this.keyword) {
        this.$message.warning("请输入搜索关键字");
        return;
      }
      window.open(this.$router.resolve({ path: `/search?keyword=${this.keyword}` }).href, '_blank');

      // this.$router.push({
      //   name: "search",
      //   query: { keyword: this.keyword },
      // });
    },
    goNoticeList() {
      this.$router.push({ name: "noticeList" });
    },
    goDocDetail(item) {
      window.open(this.$router.resolve({ path: `/docDetail?id=${item.id}` }).href, '_blank');
      // this.$router.push({ name: "docDetail", query: { id: item.id } });
    },
    goNoticeDetail() {
      this.$router.push({
        name: "noticeDetail",
        query: { identifier: this.pushedData.identifier },
      });
    },
    jumpDocClassify(item, two_cate) {
      console.log("当前分类", item);
      console.log('!item.id', !item.id);

      if (item.title == "全部分类") {
        window.open(this.$router.resolve({ path: `/documentClassify?id=${item.children[0].id}&title=全部分类` }).href, '_blank');
        // this.$router.push({ name: "documentClassify", query: { id: item.children[0].id, title: '全部分类' } });
        return
      }
      if (item.title == "文章定制" || two_cate == "文章定制") {
        window.open(this.$router.resolve({ path: `/article` }).href, '_blank');
        // this.$router.push({ path: "/article" });
        return
      }
      if (item.title == "硕博辅导" || two_cate == "硕博辅导") {
        window.open(this.$router.resolve({ path: `/tutor` }).href, '_blank');

        // this.$router.push({ path: "/tutor" });
        return
      }
      if (item.title == "文章润色/格式排版/人工降重" || two_cate == "文章润色/格式排版/人工降重") {
        window.open(this.$router.resolve({ path: `/edit` }).href, '_blank');

        // this.$router.push({ path: "/edit" });
        return
      }
      if (item.children) {
        window.open(this.$router.resolve({ path: `/documentClassify?id=${item.id}` }).href, '_blank');

        // this.$router.push({ name: "documentClassify", query: { id: item.id } });
      } else {
        window.open(this.$router.resolve({ path: `/documentClassify?id=${item.id}` }).href, '_blank');
      }
      // else {
      //   window.open(this.$router.resolve({ path: `/documentClassify?id=${item.id}&parent_id=${item.parent_id}` }).href, '_blank');
      //   // this.$router.push({
      //   //   name: "documentClassify",
      //   //   query: { id: item.id, parent_id: item.parent_id },
      //   // });
      // }
    },
    lookAll() {
      this.$router.push("/documentClassify");
    },
    backLoginInfo(token) {
      this.token = token;
      this.$router.go(0);
      // console.log("info", token);
    },
    goPartner(url) {
      window.open(url);
    },
    link_web() {
      window.location.href = this.adv.url;
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
      console.log("this.screenWidth", this.screenWidth);
    },
    backalipayLogin(msg) {
      if (msg == "ok") {
        let app_id = "2021004107669654"
        // let redirect_uri = encodeURIComponent("https://yjx37.com")
        let redirect_uri = "https://yjx37.com"
        window.location.replace(`https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=${app_id}&scope=auth_user&redirect_uri=${redirect_uri}&state=init`)
      }
    },
    jumpbannerRichText(item) {
      // window.open(this.$router.resolve({ path: `/bannerRichText?id=${id}` }).href, '_blank');
      var text = ''
      if (item.title == '全民皆是创作者') {
        text = 'qmjsczz'
      } else if(item.title == '本站释义与愿景') {
        text = 'bzsyyyj'
      }else if(item.title == '积分会员') {
        text = 'jfhy'
      }
      window.open(this.$router.resolve({ path: `/richText?text=${text}` }).href, '_blank');

    }
  },
};
</script>

<style>
/* .el-dialog {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
} */
</style>
<style lang="scss" scoped>
::v-deep .customClassDialog {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.bg {
  width: 100%;
  height: 620px;
  background: url("../assets/image/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 50px 0 0;
  box-sizing: border-box;

  .header_search {
    width: 696px;
    height: 52px;
    background: #ffffff;
    border-radius: 6px;
    padding: 0 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    img {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }

    ::v-deep .el-input__inner {
      border: none !important;
      padding-left: 0;
    }

    .search_line {
      width: 1px;
      height: 25px;
      background: #efefef;
    }

    .search_text {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      color: #23dc5a;
      margin-left: 16px;
      cursor: pointer;
    }
  }

  .tag_contain {
    width: 696px;
    margin: 15px auto 0;
    display: flex;
    align-items: center;

    .tag_contain_left {
      width: 120px;

      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      color: #ffffff;
      display: flex;
      align-items: center;

      // margin-right: 40px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }

    .tag_contain_list {
      display: flex;
      flex-wrap: wrap;

      // width: 550px;
      .tag_contain_item {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        padding: 5px 10px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #ffffff;
        margin-right: 15px;
        margin-bottom: 6px;
        cursor: pointer;
      }
    }
  }

  .contain_login {
    width: 1400px;
    margin: 51px auto 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .swiper {
    width: 956px;

    .swiper_item {
      width: 956px;
      height: 439px;
      border-radius: 6px;
    }
  }

  .swiper_active {
    width: 1400px;

    .swiper_item {
      width: 1400px;
      height: 439px;
    }
  }
}

.notice_contain {
  width: 1400px;
  height: 52px;
  background: #ffffff;
  border-radius: 6px;
  margin: 20px auto;
  padding: 0 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .notice_contain_left {
    display: flex;
    align-items: center;

    div:nth-child(1) {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #23dc5a;
      margin-right: 50px;

      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }

    div:nth-child(2) {
      max-width: 400px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
  }

  .notice_contain_right {
    display: flex;
    align-items: center;

    div:nth-child(1) {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #999999;
    }

    div:nth-child(2) {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #23dc5a;
      letter-spacing: 0.8px;
      margin-left: 252px;
      cursor: pointer;

      img {
        width: 22px;
        height: 22px;
        margin-left: 6px;
      }
    }
  }
}

.classify_contain {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  width: 1400px;
  margin: 0 auto 20px;

  .classify_contain_item {
    width: 335px;
    height: 175px;
    background: linear-gradient(220deg, #33da61 -60%, #ffffff 16%, #ffffff 10%);
    border-radius: 6px;
    padding: 20px;
    box-sizing: border-box;
    margin-right: 20px;

    .line {
      width: 295px;
      height: 1px;
      background: #f4f4f4;
      margin: 14px 0 16px;
    }

    .one {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #333333;
      letter-spacing: 0.8px;
      cursor: pointer;

      .oneicon {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
    }

    .two {
      display: flex;
      flex-wrap: wrap;

      .two_item {
        width: 78px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #333333;
        letter-spacing: 0.7px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 30px;
        margin-bottom: 15px;
        cursor: pointer;
      }

      .two_item:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  .classify_contain_item:nth-child(4n) {
    margin-right: 0;
  }
}

.hot_contain {
  width: 1400px;
  background: #ffffff;
  border-radius: 6px;
  margin: 0 auto;
  padding: 20px 20px 0;
  box-sizing: border-box;

  .hot_contain_tip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    div:nth-child(1) {
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #333333;
      letter-spacing: 0.9px;
      position: relative;

      p {
        width: 75px;
        height: 7px;
        background: linear-gradient(180deg, #23c48c, #33da61);
        position: absolute;
        bottom: -1px;
        opacity: 0.8;
      }
    }

    div:nth-child(2) {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #999999;
      letter-spacing: 0.8px;

      img {
        width: 22px;
        height: 22px;
        margin-left: 6px;
      }
    }
  }

  .hot_list {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;

    // item 650px
    .hot_list_item:nth-child(5n) {
      margin-right: 0px;

    }

    .hot_list_item {
      // width: 650px;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      cursor: pointer;
      margin-right: 16px;


      .left_img {
        width: 256px;
        height: 341px;
        background: #f4f4f4;
        border: 1px solid #c6c6c6;
        border-radius: 6px;
      }

      .hot_info {
        .hot_info_title {
          width: 256px;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #333333;
          // margin-bottom: 15px;
          margin-top: 12px;

          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;

        }

        .hot_info_desc {
          width: 376px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #666666;
          letter-spacing: 0.63px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin-bottom: 15px;
        }

        .hot_info_time {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #999999;
          letter-spacing: 0.63px;

          img {
            width: 20px;
            height: 20px;
          }
        }

        .hot_info_line {
          width: 376px;
          height: 1px;
          background: #f4f4f4;
          margin: 29px 0 21px 0;
        }
      }
    }
  }
}

.ad {
  width: 1400px;
  height: 88px;
  margin: 20px auto;
  cursor: pointer;

  img {
    width: 1400px;
    height: 88px;
  }
}

.partner_contain {
  width: 1400px;
  background: #ffffff;
  border-radius: 6px;
  margin: 0 auto;
  padding: 20px 20px 0;
  box-sizing: border-box;

  .partner_contain_tip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    div:nth-child(1) {
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #333333;
      letter-spacing: 0.9px;
      position: relative;

      p {
        width: 75px;
        height: 7px;
        background: linear-gradient(180deg, #23c48c, #33da61);
        position: absolute;
        bottom: -1px;
        opacity: 0.8;
      }
    }

    div:nth-child(2) {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #999999;
      letter-spacing: 0.8px;

      img {
        width: 22px;
        height: 22px;
        margin-left: 6px;
      }
    }
  }

  .partner_list {
    display: flex;
    flex-wrap: wrap;

    .partner_list_item {
      width: 322px;
      height: 136px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      margin-right: 20px;
      margin-bottom: 20px;
      cursor: pointer;

      img {
        width: 325px;
        height: 136px;
        border-radius: 6px;
      }
    }

    .partner_list_item:nth-child(4n) {
      margin-right: 0px;
    }
  }
}

.kf {
  width: 63px;
  height: 63px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #FC4E1E;
  letter-spacing: 0.7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 177px;
  top: 622px;
  z-index: 999;


  img {
    width: 28px;
    height: 28px;
  }
}

.kefu2 {
  width: 63px;
  height: 63px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #FC4E1E;
  letter-spacing: 0.7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 20px;
  top: 622px;
  z-index: 999;

  img {
    width: 28px;
    height: 28px;
  }
}
</style>